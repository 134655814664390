import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import './index.css'
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';
import * as Sentry from "@sentry/react";
import supabase from '~supabaseConfig';

// Sentry initialization
Sentry.init({
  dsn: "https://87f16e7899d188243aec465044e9f5fe@o4507794843500544.ingest.us.sentry.io/4507794845925376",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const msalInstance = new PublicClientApplication(msalConfig);

// Wrap your App component with a new component that handles Supabase auth
function AppWithAuth() {
  useEffect(() => {
    const setUserContext = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        Sentry.setUser({
          id: user.id,
          email: user.email,
          // Add any other user properties you want to include
        });
      }
    };

    setUserContext();

    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'SIGNED_IN' && session?.user) {
        Sentry.setUser({
          id: session.user.id,
          email: session.user.email,
          // Add any other user properties you want to include
        });
      } else if (event === 'SIGNED_OUT') {
        Sentry.setUser(null);
      }
    });

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  return <App />;
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
      <MsalProvider instance={msalInstance}>
        <AppWithAuth />
      </MsalProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);

function ErrorFallback({error}) {
 console.log(error)
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      {/* <pre>{error.message}</pre> */}
    </div>
  )
}