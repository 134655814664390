import React from 'react';
import { useParams } from 'react-router-dom';
import { Input, Form, Typography, Button, InputNumber, Select, DatePicker, Row, Col } from 'antd';
import pageTemplate from '../../../components/pageTemplate';
import FormLayout from '../../../components/FormLayout';

const { Title } = Typography;
const { TextArea } = Input;

const Repro = () => {
 const { farmId, rvmId } = useParams();
 const { form, onFinish } = pageTemplate(
  'rvm_repro_data',
  'rvm_id',
  'repro_id',
  farmId,
  rvmId,
  'rvm_id',
  'rvms'
 );

 return (
  <FormLayout title="Repro" form={form} onFinish={onFinish}>
   <Row gutter={16} style={{ textAlign: 'left' }}>
    <Col span={12}>
     <Form.Item label="6 week ICR" name="six_week_icr">
      <InputNumber />
     </Form.Item>
    </Col>
    <Col span={12}>
     <Form.Item label="Empty rate" name="empty_rate">
      <InputNumber />
     </Form.Item>
    </Col>
   </Row>
   <Row gutter={16} style={{ textAlign: 'left' }}>
    <Col span={12}>
     <Form.Item label="Mating length (weeks)" name="mating_length_weeks">
      <InputNumber />
     </Form.Item>
    </Col>
    <Col span={12}>
     <Form.Item label="R2 empty rate" name="r2_empty_rate">
      <InputNumber />
     </Form.Item>
    </Col>
   </Row>
   <Row gutter={16} style={{ textAlign: 'left' }}>
    <Col span={24}>
     <Form.Item label="Scanning" name="scanning">
      <Select
       allowClear
       defaultValue="FVS early"
       options={[
        { value: 'FVS early', label: 'FVS early' },
        { value: 'FVS late only', label: 'FVS late only' },
        { value: 'Ultrascan/Other', label: 'Ultrascan/Other' },
        { value: 'Milk Testing', label: 'Milk Testing' },
        { value: 'Not done', label: 'Not done' },
       ]}
      />
     </Form.Item>
    </Col>
   </Row>
   <Row gutter={16} style={{ textAlign: 'left' }}>
    <Col span={24}>
     <Form.Item label="Tick services planning to use" name="services">
      <Select
       mode="multiple"
       allowClear
       style={{ width: '100%' }}
       placeholder="Please select"
       options={[
        { label: 'Dry off guide', value: 'Dry off guide' },
        { label: 'Heifer synchrony', value: 'Heifer synchrony' },
        { label: 'Metrichecked', value: 'Metrichecked' },
        { label: 'NVO Treatment', value: 'NVO Treatment' },
        { label: 'Incalf program', value: 'Incalf program' },
       ]}
      />
     </Form.Item>
    </Col>
   </Row>
   <Row gutter={16} style={{ textAlign: 'left' }}>
    <Col span={24}>
     <Form.Item label="Comments" name="comments">
      <TextArea placeholder="Controlled autosize" autoSize={{ minRows: 3, maxRows: 5 }} />
     </Form.Item>
    </Col>
   </Row>
   <Title level={5}>Planned start of mating</Title>
   <Row gutter={16} style={{ textAlign: 'left' }}>
    <Col span={12}>
     <Form.Item label="Spring:" name="mating_start_date">
      <input type="date" />
     </Form.Item>
    </Col>
    <Col span={12}>
     <Form.Item label="Autumn:" name="mating_end_date">
      <input type="date" />
     </Form.Item>
    </Col>
   </Row>
   <Title level={5}>BVD</Title>
   <Row gutter={16} style={{ textAlign: 'left' }}>
    <Col span={24}>
     <Form.Item label="Herd testing" name="herd_testing">
      <Select
       allowClear
       options={[
        { value: 'Herd Guardian Package 1', label: 'Herd Guardian Package 1' },
        { value: 'Herd Guardian Package 2', label: 'Herd Guardian Package 2' },
        { value: 'Herd Guardian Package 3', label: 'Herd Guardian Package 3' },
        { value: 'Herd Guardian Package 4', label: 'Herd Guardian Package 4' },
        { value: 'Other', label: 'Other' },
       ]}
      />
     </Form.Item>
    </Col>
   </Row>
   <Row gutter={16} style={{ textAlign: 'left' }}>
    <Col span={24}>
     <Form.Item label="Testing" name="herd_animals_tested">
      <Select
       mode="multiple"
       allowClear
       style={{ width: '100%' }}
       placeholder="Please select"
       options={[
        { value: 'Bulls', label: 'Bulls' },
        { value: 'Heifers', label: 'Heifers' },
        { value: 'Calves', label: 'Calves' },
       ]}
      />
     </Form.Item>
    </Col>
   </Row>
   <Row gutter={16} style={{ textAlign: 'left' }}>
    <Col span={24}>
     <Form.Item label="Vaccinations" name="herd_animals_vaccinated">
      <Select
       mode="multiple"
       allowClear
       style={{ width: '100%' }}
       placeholder="Please select"
       options={[
        { value: 'Herd', label: 'Herd' },
        { value: 'Heifers', label: 'Heifers' },
        { value: 'Calves', label: 'Calves' },
        { value: 'Bulls', label: 'Bulls' },
       ]}
      />
     </Form.Item>
    </Col>
   </Row>
  </FormLayout>
 );
};

export default Repro;
