import React, { useEffect, useState } from 'react';
import supabase from '~supabaseConfig';
import Box from '@mui/joy/Box';
import FarmTable from './components/FarmTable';
import SearchIcon from '@mui/icons-material/Search';
import Input from '@mui/joy/Input'; // Import Input from @mui/joy
import FormLabel from '@mui/joy/FormLabel';
import { Button, message } from 'antd';
import FarmForm from './components/FarmForm';
import BoxWrapper from '../dashboard/components/BoxWrapper'

const Home = () => {
 const [farms, setFarms] = useState([]);
 const [open, setopen] = useState(false);
 const [clinics, setClinics] = useState([]);
 const [searchQuery, setSearchQuery] = useState('');

 const handleSearchChange = (e) => {
  setSearchQuery(e.target.value);
  console.log(e.target.value); // Check if searchQuery updates as you type
 };

 // Call the function to check authentication state
 useEffect(() => {

  async function fetchClinicsAndFarms() {
   try {
    // Fetch clinics
    const { data: clinicsData, error: clinicsError } = await supabase
     .from('clinics')
     .select('id, clinic_name');

    if (clinicsError) {
     throw new Error(`Error fetching clinics: ${clinicsError.message}`);
    } else {
     setClinics(clinicsData || []);
    }

    // Fetch farms
    const { data: farmsData, error: farmsError } = await supabase
     .from('farms')
     .select('id, farm_name, address, farmer_name, email');

    if (farmsError) {
     throw new Error(`Error fetching farms: ${farmsError.message}`);
    } else {
     // Transform the fetched data to fit the desired object structure
     const transformedData = farmsData.map((farm) => ({
      id: farm.id,
      farm: farm.farm_name || 'N/A',
      address: farm.address || 'N/A',
      farmer: farm.farmer_name || 'N/A',
      email: farm.email || 'N/A',
     }));
     setFarms(transformedData || []);
    }
   } catch (error) {
    console.error(error);
   }
  }
  fetchClinicsAndFarms();
 }, []);

 const handleAddFarm = async (values) => {
  try {
   const { data, error } = await supabase.from('farms').insert([values]);

   if (error) {
    throw new Error(`Error adding farm: ${error.message}`);
   } else {
    setopen(false);
    fetchFarms(); // Fetch the updated farm list after adding a new farm
   }
  } catch (error) {
   console.error(error);
  }
 };

 const fetchFarms = async () => {
  try {
   const { data: farmsData, error: farmsError } = await supabase
    .from('farms')
    .select('id, farm_name, address, farmer_name, email');

   if (farmsError) {
    throw new Error(`Error fetching farms: ${farmsError.message}`);
   } else {
    const transformedData = farmsData.map((farm) => ({
     id: farm.id,
     farm: farm.farm_name || 'N/A',
     address: farm.address || 'N/A',
     farmer: farm.farmer_name || 'N/A',
     email: farm.email || 'N/A',
    }));
    setFarms(transformedData || []);
   }
  } catch (error) {
   console.error(error);
  }
 };

 return (
  <BoxWrapper>
   <FarmForm
    visible={open}
    onClose={() => setopen(false)}
    onSubmit={handleAddFarm}
   />
   <FormLabel>Search for a farm</FormLabel>
   <Box
    sx={{
     display: 'flex',
     flexDirection: 'row',
     flexWrap: 'wrap',
     justifyContent: 'space-between',
     alignItems: 'center',
     width: '100%',
     gap: { xs: 1, sm: 0 },
    }}
   >
    <Input
     value={searchQuery}
     onChange={handleSearchChange}
     placeholder="Search farms"
     startDecorator={<SearchIcon />}
     sx={{
      flexGrow: 1,
      maxWidth: { xs: '100%', sm: 'calc(100% - 140px)' },
      mb: { xs: 1, sm: 0 },
     }}
    />
    <Button
     type="primary"
     sx={{
      whiteSpace: 'nowrap',
      minWidth: { xs: '100%', sm: '120px' },
     }}
     onClick={() => setopen(true)}
    >
     Add New Farm
    </Button>
   </Box>
   <FarmTable farms={farms} clinics={clinics} searchQuery={searchQuery} />
  </BoxWrapper>
 );
};

export default Home;
