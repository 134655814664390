import React from 'react';
import { Form, Input, InputNumber, Row, Col } from 'antd';
import { useParams } from 'react-router-dom';
import pageTemplate from '../../../components/pageTemplate';
import FormLayout from '../../../components/FormLayout';

const FarmObjectives = () => {
  const { farmId, rvmId } = useParams();
  const { form, onFinish } = pageTemplate(
    'rvm_farm_objectives_data',
    'rvm_id',
    'farm_objectives_id',
    farmId,
    rvmId,
    'rvm_id',
    'rvms'
  );

  return (
    <FormLayout title="Farm Objectives" form={form} onFinish={onFinish}>
      <Row gutter={16} style={{ textAlign: 'left' }}>
        <Col span={12}>
          <Form.Item label="Consult date" name="consult_date">
            <input type='date' />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Effective Farm Size (ha)" name="effective_farm_size" required>
            <InputNumber />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} style={{ textAlign: 'left' }}>
        <Col span={12}>
          <Form.Item label="Total Milk Production" name="total_milk_production" required>
            <InputNumber />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Herd size (Peak)" name="herd_size" required>
            <InputNumber />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} style={{ textAlign: 'left' }}>
        <Col span={12}>
          <Form.Item label="Number heifers (peak)" name="heifer_size" required>
            <InputNumber />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Calves Reared" name="calves_reared">
            <InputNumber />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} style={{ textAlign: 'left' }}>
        <Col span={12}>
          <Form.Item label="Planned Start of Calving Spring" name="planned_start_of_calving_spring">
            <input type='date' />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Planned Start of Calving Autumn" name="planned_start_of_calving_autumn">
            <input type='date' />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} style={{ textAlign: 'left' }}>
        <Col span={8}>
          <Form.Item label="Goal 1" name="goal_one">
            <Input placeholder="Goal 1" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} style={{ textAlign: 'left' }}>
        <Col span={8}>
          <Form.Item label="Goal 2" name="goal_two">
            <Input placeholder="Goal 2" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} style={{ textAlign: 'left' }}>
        <Col span={8}>
          <Form.Item label="Goal 3" name="goal_three">
            <Input placeholder="Goal 3" />
          </Form.Item>
        </Col>
      </Row>
    </FormLayout>
  );
};

export default FarmObjectives;